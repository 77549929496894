'use strict';

/**
 * @ngdoc function
 * @name diceApp.controller:dailyParticipation
 * @description
 * dailyParticipation Shows the daily participation
 */
angular.module('diceApp').component('dailyParticipation', {
  bindings: {
    success: '<',
    fromDate: '<',
    toDate: '<'
  },
  templateUrl: 'es6/base/audit/daily.participation.html',
  controllerAs: 'dailyParticipationController',
  controller: function (diceDateFilter, ParticipantSession, Pageable) {
    const dailyParticipationController = this;

    dailyParticipationController.$onInit = function () {
      dailyParticipationController.options = {};

      dailyParticipationController.pageable = Pageable.of({
        order: 'participationDate',
        reverse: true
      });

      loadData();
    };

    dailyParticipationController.$onChanges = function () {
      if (dailyParticipationController.pageable) {
        loadData();
      }
    };

    dailyParticipationController.onPage = function () {
      loadData();
    };

    function loadData() {
      const parameters = dailyParticipationController.pageable.build({
        success: dailyParticipationController.success,
        fromDate: dailyParticipationController.fromDate,
        toDate: dailyParticipationController.toDate
      });

      dailyParticipationController.loading = true;
      ParticipantSession.daily(parameters)
        .$promise.then((page) => {
          dailyParticipationController.page = page;

          const mapped = page.content
            .map((row) => {
              return {
                label: diceDateFilter(row.participationDate),
                date: row.participationDate,
                value: row.amount
              };
            })
            .reverse();

          dailyParticipationController.chartData = {
            type: 'bar',
            data: {
              labels: _.map(mapped, 'label'),
              datasets: [{ data: _.map(mapped, 'value') }]
            }
          };
        })
        .finally(() => {
          dailyParticipationController.loading = false;
        });
    }
  }
});
