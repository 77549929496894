'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:memberReport
 */
angular.module('diceApp')
  .component('memberReport', {
    templateUrl: 'es6/report/condensed/member/member.report.html',
    controllerAs: 'reportController',
    controller: function (CondensedReportTemplate, QuestionTemplate, MemberReport, Pageable, Promises, language, descriptionFilter, stripHtmlFilter, $q) {
      const reportController = this;

      reportController.$onInit = function() {
        reportController.pageable = Pageable.of({
          order: 'displayName'
        });

        CondensedReportTemplate.query().$promise.then((templates) => {
          reportController.templates = _(templates).filter({ personal: true }).map((template) => {
            return _.extend(template, { key: `${template.id}` });
          }).orderBy(['year', 'id'], ['desc', 'asc']).value();

          reportController.parameters = {
            templateId: _(reportController.templates).map('id').head()
          };

          setReportColumns();
        });
      };

      reportController.onTemplate = function() {
        setReportColumns();
      };

      function setReportColumns() {
        let columns = [{
          code: 'personName',
          name: 'Person',
          visible: true
        }, {
          code: 'code',
          name: 'Code',
          visible: true
        }, {
          code: 'name',
          name: 'Name',
          visible: true
        }, {
          code: 'periodName',
          name: 'Period',
          visible: true
        }, {
          code: 'amountOfParticipants',
          name: 'Participants',
          visible: true
        }];

        getQuestionTemplates().then((questionTemplates) => {
          const questions = _.map(questionTemplates, (questionTemplate) => ({
            code: `questions.${questionTemplate.id}`,
            name: stripHtmlFilter(descriptionFilter(questionTemplate.labels)),
            visible: true
          }));

          reportController.columns = _.concat(columns, questions);
        });
      }

      reportController.search = function() {
        const pageable = reportController.pageable.build();

        reportController.loading = true;
        getPersons(pageable).then((persons) => {
          reportController.persons = persons;
        }).finally(() => {
          reportController.loading = false;
        });
      };

      function getPersons(pageable) {
        const parameters = _.extend({
          language: language.get()
        }, reportController.parameters);

        return MemberReport.get(_.extend(parameters, pageable)).$promise;
      }

      reportController.expand = function(person) {
        person.expanded = person.expanded !== true;
        if (person.expanded && angular.isUndefined(person.loading)) {
          load(person);
        }
      };

      function load(person) {
        const parameters = _.extend(reportController.parameters, {
          language: language.get(),
          personId: person.id
        });
        
        person.loading = true;
        return MemberReport.evaluations(parameters).$promise.then((evaluations) => {
          person.evaluations = evaluations;
        }).finally(() => {
          person.loading = false;
        });
      }

      reportController.getRows = function(page) {
        const pageable = reportController.pageable.build({ page, size: 50 });

        return getPersons(pageable).then((data) => {
          const persons = data.content;
          const requests = _.map(persons, (person) => () => load(person));
          return Promises.chain(requests).$promise.then(() => {
            const rows = mapToRows(persons);
            return _.extend(data, { content: rows });
          });
        });
      };

      function mapToRows(persons) {
        return _(persons).map(({ displayName: personName, evaluations }) => {
          return _.map(evaluations, ({ code, name, periodName, amountOfParticipants, questionAnswers: questions }) => {
            let row = { personName, code, name, periodName, amountOfParticipants, questions: {} };
            _.forEach(questions, (question) => {
              const label = `${question.averageValue} / ${question.medianValue} / ${question.standardDeviationValue}`;
              row.questions[question.questionTemplateId] = label;
            });
            return row;
          });
        }).flatten().value();
      }

      function getQuestionTemplates() {
        const template = _.find(reportController.templates, {
          id: reportController.parameters.templateId
        });

        const questionTemplates = _.get(template, 'questionTemplates');
        if (_.isEmpty(questionTemplates)) {
          return $q.resolve([]);
        }

        return QuestionTemplate.bulk({
          id: _.map(questionTemplates, 'questionTemplateId')
        }).$promise;
      }
    }
  });
