'use strict';

/**
 * @ngdoc filter
 * @name diceApp.filter:visualization
 * @description
 * Filter question on visualization type
 */
angular.module('diceApp').filter('visualization', function () {
  return function (blocks, types) {
    if (blocks.length > 0 && types.length > 0) {
      var filtered = [];

      _.forEach(blocks, function (block) {
        let newBlock = angular.copy(block);
        newBlock.questionReports = [];

        _.forEach(block.questionReports, function (question) {
          const { visualization } = question;
          question.graph = visualization;
          question.visualization =
            visualization === 'HISTOGRAM_WITHOUT_AVERAGE'
              ? 'HISTOGRAM'
              : visualization;

          if (_.includes(types, question.visualization)) {
            newBlock.questionReports.push({ ...question });
          }
        });

        if (newBlock.questionReports.length > 0) {
          filtered.push(newBlock);
        }
      });

      return filtered;
    } else {
      return blocks;
    }
  };
});
