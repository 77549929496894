'use strict';

import { isEmpty } from 'lodash';

/**
 * @ngdoc function
 * @name diceApp.controller:diceReportHistogram
 * @description
 * diceReportHistogram
 */
angular.module('diceApp').component('diceReportHistogram', {
  bindings: {
    question: '<'
  },
  templateUrl: 'es6/report/report.histogram.html',
  controllerAs: 'reportHistogramController',
  controller: function () {
    const reportHistogramController = this;

    reportHistogramController.$onInit = function () {
      reportHistogramController.enableTooltips = false;

      const options = getOptions();
      if (!options) return;

      const answers = options.map((option) => option.percentage || 0);
      if (reportHistogramController.question.graph === 'HISTOGRAM') {
        reportHistogramController.avarage = {
          average: reportHistogramController.question.averageValue,
          standardDeviation:
            reportHistogramController.question.standardDeviationValue
        };
      }

      reportHistogramController.chartData = {
        data: {
          labels: options.map(({ chartLabel }) => chartLabel),
          datasets: [{ type: 'bar', data: answers }]
        },
        options: getReportOptions(options)
      };
    };

    function getOptions() {
      if (!isEmpty(reportHistogramController.question.calculableOptions)) {
        return reportHistogramController.question.calculableOptions.map(
          (option) => {
            option.chartLabel = parseInt(option.value);
            return option;
          }
        );
      }

      if (!isEmpty(reportHistogramController.question.nonCalculableOptions)) {
        reportHistogramController.enableTooltips = true;
        return reportHistogramController.question.nonCalculableOptions.map(
          (option) => {
            option.chartLabel = option.label;
            if (isEmpty(option.chartLabel)) {
              option.chartLabel = option.value;
            }
            return option;
          }
        );
      }
    }

    const getReportOptions = (options) => ({
      defaultFontSize: 6,
      animation: false,
      scales: {
        y: {
          ticks: {
            min: 0,
            stepSize: 25,
            callback: function (value) {
              return value + ' %';
            }
          }
        },
        x: {
          position: 'bottom',
          ticks: {
            fontColor: options
              .map(({ thresholdColor }) => thresholdColor)
              .find((thresholdColor) => thresholdColor),
            stepSize: 1,
            maxRotation: 0
          },
          gridLines: {
            display: true
          }
        }
      },
      plugins: {
        tooltip: {
          enabled: reportHistogramController.enableTooltips,
          intersect: false,
          mode: 'index',
          callbacks: {
            label: function () {
              // Only show title in tooltip
              return '';
            }
          }
        }
      }
    });
  }
});
